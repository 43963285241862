@-webkit-keyframes pulse-bg {
  0% {
    background-color: rgba(255, 255, 255, 0.88);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.59);
  }
}

#FrontDesk {
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  .main {
    * .container {
      max-width: 100%;
      width: 100% !important;
      margin-left: 0px !important;
      margin-right: 0px !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
      padding: 0px !important;
    }
    .front-desk-background {
      margin-left: 0px !important;
      margin-right: 0px !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
      padding: 0px !important;
    }
  }

  .overlay {
    background-color: rgba(255, 255, 255, 0.88);
    max-width: 100% !important;
    width: 100% !important;

    -webkit-animation: pulse-bg 6s infinite alternate;
    -webkit-animation-delay: 5s;

    .white-overlay {
      margin-left: 0px !important;
      margin-right: 0px !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
      padding: 0px !important;
      width: 100% !important;
      max-width: 100% !important;
    }
  }
  .full-height {
    min-height: 100vh;
  }

  .campus-name {
    color: #283845;
    text-transform: uppercase;
    font-size: 60px;
    font-weight: bold;
  }

  .check-in-btn {
    border-radius: 50vh;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
  .btn-text {
    font-weight: 520;
    text-transform: uppercase;
    font-size: 20px;
  }

  .member-btn {
    position: absolute;
    bottom: 30px;
    left: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #283845;
    font-size: 15px;
  }
}

.cancel-btn {
  color: #283845;
}

.member-modal {
  border: none !important;
}

#BookingAuth {
  height: 640px !important;
}

.input-style {
  border: #ccc solid 1px;
  border-radius: 5px;
  padding: 4px;
  font-size: 13px;

  &:focus {
    outline: none;
  }
}

.request-tour {
  display: flex;
  justify-content: center;
  align-items: center;

  .request-btn {
    background-color: #283845;
    border: none;
    border-radius: 50vh;
    color: white;
    font-weight: 520;
    text-transform: uppercase;
    height: 60px;
    font-size: 20px;
    margin-top: 30px !important;
  }
}

.notify-member {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-styled-title {
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 600;
}

.tour-title {
  //This is some bullshit right here
  // margin-top: -10px;
  // margin-bottom: 75px;
  // width: 650px !important;
}

.member-title {
  color: #283845;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 600;
}

.success-title {
  color: #283845;
  text-transform: uppercase;
  font-size: 55px;
  font-weight: 600;
  text-align: center;
}

.visitor-message {
  border-radius: 10px;

  &:focus {
    border-color: lightgrey;
  }
}

.cancel-btn {
  border: none;
  color: #283845;
  font-size: 17px;
}

.send-btn {
  background-color: #283845;
  color: white;
  border-radius: 50vh;
  border: none;
  font-size: 17px;
}

.modal-header {
  border-bottom: none;
}

.modal-footer {
  border-top: none;
}

#member-cards {
}
