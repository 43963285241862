.text-white-shadow {
  color: #fff;
  text-shadow: 0px 3px 10px rgba(0, 0, 0, 0.5);
}

.form-bubble {
  border: #ccc solid 2px;
  padding: 27px;
  border-radius: 10px;
  font-size: 22px;
  margin-top: 20px;

  &:focus {
    outline: none;
  }
}

@keyframes primary-pulse {
  from {
    background-color: saturate(transparentize($brand-primary, 0.05), 75%);
  }
  to {
    background-color: saturate(transparentize($brand-primary, 0.1), 0%);
  }
}

#BookingTablet {
  // height: 100vh;
  // position: absolute;
  // background-size: cover;
  &.room-open {
    .booking-room {
      &-description {
        @include linearGradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
      }
    }
  }
  .booking-room {
    &-description {
      transition: 2s;
    }
    &-sidebar {
      transition: 2s;
      height: 100vh;
      .btn-lg {
        margin-top: 20px;
        font-size: 3em;
        font-weight: bold;
        border-width: 5px;
        border-radius: 50px;
      }
    }
    &-cards {
      height: 40vh;
      transition: 0.3s;
      transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      overflow-y: scroll;
      overflow-x: hidden;
      &.active {
        height: 40vh;
        padding-top: 10px;
        border-top: solid #fff 1px;
      }
      &.inactive {
        height: 0px;
        transition: 0.3s;
        border-top: solid #fff 0px;
      }
    }
    &-time {
      @extend .text-white-shadow;
      font-size: 5em;
    }
    &-date {
      color: saturate(transparentize($brand-primary, 0.5), 50%);
    }
    &-name {
      @extend .text-white-shadow;
      font-size: 5em;
    }
    &-status {
      font-size: 7em;
    }
  }
  .booked-buttons {
    * button {
      // margin-top: 20px;
      font-size: 20px;
      padding: 20px;
      // display: block;
    }
  }
}

// Modal
#BookingAuth {
  // position: absolute;
  max-width: calc(100vw - 60px);
  min-height: 80vh;
  // height: calc(100vh - 60px);
  margin: 30px;
  // overflow: hidden;
  // position: fixed;
  .booking-auth-header {
    background-color: #fff;
    padding: 20px;
    border-bottom: solid 1px #ccc;
    .btn-link {
      // margin-top: 20px;
      font-size: 30px;
    }
  }
  .modal-content {
    height: calc(100vh - 60px);
    overflow: scroll;
  }
  .member-avatar {
    height: 200px;
    width: 200px;
    border-radius: 100px;
    background-position: center;
    background-size: cover;
  }
  .passwordForm {
    height: calc(100vh - 80px);
    div {
      // border: solid 1px #000;
    }
  }
}

#MemberCard {
  margin-top: 200px;

  // height: calc(100vh - 60px);
  overflow: scroll;
  .card {
    transition: 1s;
  }
  .active {
    background-color: $brand-primary;
  }
}
