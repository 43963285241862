#ScheduleTourForm{
  .logo-wrapper{
    height: 10rem;
    width: 10rem;
    background: #fff;
    border: solid #ccc 1px;
    border-radius: 10rem;
    background-size: 8rem;
    background-repeat: no-repeat;
    background-position: center center;

  }
}
