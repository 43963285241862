

#EventFeed{
	// min-height: 100vh;
	.main{
		* .container{
			max-width: 100%;
			width: 100% !important;
			margin-left: 0px !important;
			margin-right: 0px !important;
			padding-left: 0px !important;
			padding-right: 0px !important;
			padding: 0px !important;		
		}
		.container-fluid{
			margin-left: 0px !important;
			margin-right: 0px !important;
			padding-left: 0px !important;
			padding-right: 0px !important;
			padding: 0px !important;
		}
	}
	.card{
		background-size: cover ;
		border: solid 1px #fff;
	}
	.overlay{
		background-color: rgba(0,0,0,.55);
		&-gradient{
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&1+0,0+100 */
			background: -moz-linear-gradient(left, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to right, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */			
		}
	}
	.full-height{
		min-height: 100vh;
	}
}