.EventRegisterModal{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

#ExternalEventPage{
    .fa-times{
      cursor: pointer;
    }
    .display-1{
      font-size: 2.5rem;
    }
    .event-image-background{
      height: 40vh;
      background-size: cover;
    }
    .backgroundBlurImage{
      position: absolute;
      width: 100%;
      height: 50vh;
      filter: blur(75px);
    }
    .register-sidebar{
      border-left: solid 2px #ccc;
    }
}


// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {


}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  #ExternalEventPage{
    .display-1{
      font-size: 5rem;
    }
  }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {


}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {


}
