.email-preview-header {
  min-height: 175px;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .email-preview-subject {
    font-size: 30px;
    width: 100%;
    color: white;
    text-align: center;
    padding: 20px;
    font-weight: bold;
  }
}

.email-preview-signature {
  border-top: 1px solid lightgrey;

  .email-preview-name {
    font-weight: 550;
    font-size: 22px;
  }
}

// MMEMBER MODAL

.member-list-wrapper {
  max-height: 800px;
  overflow-y: scroll;
}

// REVIEW MODAL

.member-count-row {
  display: flex;
  justify-content: flex-end;

  .email-recipient-count {
    border: none;
    color: #479494;

    &:hover {
      background-color: transparent !important;
      font-weight: bold;
    }
  }
}

.email-review-label {
  font-weight: 550;
}

.email-review-subject {
  font-size: 18px;
}

.email-review-all-recipient-wrapper {
  max-height: 500px;
  overflow: scroll;
  display: flex;
  align-items: center;
  flex-direction: column;

  .email-review-list-recipient {
    border-radius: 500vh;
    padding: 3px 8px;
    margin-bottom: 8px;
    font-size: 14px;
  }
}

.email-review-recipient-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: auto;

  .email-review-recipient {
    border-radius: 500vh;
    padding: 3px 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    font-size: 13px;
  }
}
