//@import "~bootstrap/scss/bootstrap.scss";

// Import normalize
@import 'core-ui/bootstrap/normalize';

// Override Boostrap variables
@import 'coworks/global/bootstrap-variables';

// Import Bootstrap source files
@import 'core-ui/bootstrap/bootstrap';

@import 'custom-variables';
@import 'core-ui/mixins';

@import 'core-ui/events';

@import 'coworks/onboarding/login';

// Import vendors styles
@import 'core-ui/vendors/vendors';

// Additional typography
@import 'core-ui/typography';

// Animations
@import 'core-ui/animate';

// Components
@import 'core-ui/aside';
@import 'core-ui/avatars';
@import 'core-ui/badge';
@import 'core-ui/breadcrumb-menu';
@import 'core-ui/breadcrumb';
@import 'core-ui/buttons';
@import 'core-ui/callout';
@import 'core-ui/card';
@import 'core-ui/charts';
@import 'core-ui/dropdown';
@import 'core-ui/footer';
@import 'core-ui/grid';
@import 'core-ui/input-group';
@import 'core-ui/loading';
@import 'core-ui/modal';
@import 'core-ui/nav';
@import 'core-ui/navbar';
@import 'core-ui/progress';
@import 'core-ui/sidebar';
@import 'core-ui/switches';
@import 'core-ui/tables';
@import 'core-ui/widgets';

// Layout Options
@import 'core-ui/layout';

@import 'core-ui/others';

// Utility classes
@import 'core-ui/utilities';

// Temporary fixes
@import 'core-ui/temp';

// Right-to-left
@import 'core-ui/rtl';

/* ----------------- BEGIN COWORKS STYLES -------------------*/

@import 'coworks/index';
@import 'coworks/global/global-image-upload';
@import 'coworks/global/panel-errors';
// @import "coworks/components/bookings/bookingTablet";
