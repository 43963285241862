#new-member-form {
  i {
    font-size: 1.5em;
  }

  .upload-photo {
    cursor: pointer;
  }

  .primary-bg {
    background: #269796;
  }

  .centered-circle {
    height: 200px;
    width: 200px;
    border-radius: 200px;
    display: block;
    margin: auto;
  }

  #imageUploadPlaceholder i {
    padding-top: 80px;

  }

  #imageFrame {
    padding-bottom: 0 !important;
    background-size: cover !important;
  }
}